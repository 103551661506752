import { useNavigate } from '@remix-run/react';
import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { z } from 'zod';

import { ListViewCall } from '~/services/talk/models/Call';

export type CloseNotificationFunction = () => void;
export type ShowNotificationFunction = (
  notification: {
    id: string;
    title: string;
    subtitle?: string;
    body?: string;
    hasReply?: boolean;
    avatarUrl?: string;
  },
  events: {
    onClick?(): void;
    onReply?(message: string): void;
  }
) => CloseNotificationFunction;

export interface OpenWindowFunction {
  (
    options: {
      id: string;
      load: {
        path: `/${string}`;
        data: Exclude<ListViewCall['caller'], undefined>;
      };
      height: number;
      width: number;
    },
    cb: (action: string) => void
  ): CloseNotificationFunction;
}

type ElectronContextValue = {
  isElectron: boolean;
  port: MessagePort | null;
  fullscreen: boolean;
  focused: boolean;
  openWindow: OpenWindowFunction;
  showNotification: ShowNotificationFunction;
};

const value: ElectronContextValue = {
  isElectron: import.meta.env.VITE_PLATFORM === 'DESKTOP',
  port: null as MessagePort | null,
  fullscreen: false,
  focused: true,
  openWindow() {
    return () => {};
  },
  showNotification() {
    return () => {};
  }
};

export const ElectronContext = createContext(value);

if (!import.meta.env.SSR) {
  window.addEventListener('message', (event) => {
    if (event.source === window && event.data === 'main-world-port') {
      value.port = event.ports[0];
    }
  });
}

export function ElectronProvider({
  children
}: Omit<ProviderProps<object>, 'value'>) {
  const context = useContext(ElectronContext);
  const [port, setPort] = useState<MessagePort | null>(context.port);

  useEffect(() => {
    window.onmessage = (event) => {
      if (event.source === window && event.data === 'main-world-port') {
        const [port] = event.ports;
        setPort(port);
      }
    };

    return function cleanup() {
      window.onmessage = null;
    };
  }, []);

  const [fullscreen, setFullscreen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    function handleMessage(event: MessagePortEventMap['message']) {
      switch (event.data.type) {
        case 'navigation': {
          return navigate(event.data.to);
        }
        case 'tel': {
          return window.postMessage(event.data);
        }
        case 'enter-full-screen': {
          return setFullscreen(true);
        }
        case 'leave-full-screen': {
          return setFullscreen(false);
        }
        case 'dispatchEvent': {
          document.dispatchEvent(
            new CustomEvent(event.data.eventName, { detail: event.data.detail })
          );
        }
      }
    }

    port?.addEventListener('message', handleMessage);
    port?.start();

    return function cleanup() {
      port?.removeEventListener('message', handleMessage);
    };
  }, [navigate, port]);

  const [focused, setFocused] = useState(context.focused);

  useEffect(() => {
    function handleVisibilityChange(event: Event) {
      if (event instanceof CustomEvent) {
        setFocused(event.detail.visibilityState === 'visible');
      } else {
        setFocused(document.hasFocus());
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return function cleanup() {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [port]);

  const value = useMemo<ElectronContextValue>(
    () => ({
      ...context,
      focused,
      port,
      fullscreen,
      showNotification(notification, events) {
        const actionSchema = z.union([
          z.object({
            notificationId: z.string(),
            action: z.literal('click')
          }),
          z.object({
            notificationId: z.string(),
            action: z.literal('reply'),
            reply: z.string()
          })
        ]);

        port?.postMessage({
          showNotification: notification
        });

        function handleAction(event: MessagePortEventMap['message']) {
          const { success, data } = actionSchema.safeParse(event.data);
          if (success && data.notificationId === notification.id) {
            switch (data.action) {
              case 'click':
                events.onClick?.();
                break;
              case 'reply':
                events.onReply?.(data.reply);
                break;
            }

            port?.removeEventListener('message', handleAction);
          }
        }

        port?.addEventListener('message', handleAction);

        return function closeNotification() {
          port?.removeEventListener('message', handleAction);
          port?.postMessage({ closeNotification: { id: notification.id } });
        };
      },
      openWindow(options, cb) {
        const actionSchema = z.object({
          windowId: z.string(),
          action: z.string()
        });

        port?.postMessage({
          openWindow: options
        });

        function handleAction(event: MessagePortEventMap['message']) {
          const { success, data } = actionSchema.safeParse(event.data);
          if (success && data.windowId === options.id) {
            cb(data.action);
            port?.removeEventListener('message', handleAction);
          }
        }

        port?.addEventListener('message', handleAction);

        return function closeWindow() {
          port?.removeEventListener('message', handleAction);
          port?.postMessage({ closeWindow: { id: options.id } });
        };
      }
    }),
    [context, focused, fullscreen, port]
  );

  return (
    <ElectronContext.Provider value={value}>
      {children}
    </ElectronContext.Provider>
  );
}
